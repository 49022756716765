import React from 'react'
import { Typography } from '@mui/material'
import styles from './Section.module.scss'
import clsx from 'clsx'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

interface ISectionProps {
  content: React.ReactNode
  title?: string
  rightAdornment?: React.ReactNode
  rightAdornmentText?: string
  rightAdornmentLinkTo?: string
  className?: string
  titlePosition?: 'start' | 'center'
}

export const Section: React.FC<ISectionProps> = (props) => {
  const {
    title,
    rightAdornment,
    rightAdornmentText,
    rightAdornmentLinkTo,
    content,
    className,
    titlePosition = 'start',
  } = props
  const history = useHistory()

  const handleRightAdornmentClick = () => {
    if (!rightAdornmentLinkTo) return

    history.push(rightAdornmentLinkTo)
  }

  return (
    <div className={clsx(styles['section-wrapper'], className)}>
      {(title || rightAdornmentText) && (
        <div className={clsx(styles['section-header'], styles[`${titlePosition}-position`])}>
          {title && (
            <Typography variant="headline3Bold" className={styles['section-title']}>
              {title}
            </Typography>
          )}
          {!rightAdornment && rightAdornmentText && (
            <Typography
              variant="bodyBold"
              onClick={handleRightAdornmentClick}
              className={styles['right-adornment']}
            >
              {rightAdornmentText}
            </Typography>
          )}
          {!rightAdornmentText && rightAdornment}
          {rightAdornment && rightAdornmentText && (
            <div className={styles.both}>
              {rightAdornment}
              <Typography
                variant="bodyBold"
                onClick={handleRightAdornmentClick}
                className={styles['right-adornment']}
              >
                {rightAdornmentText}
              </Typography>
            </div>
          )}
        </div>
      )}
      {content}
    </div>
  )
}
