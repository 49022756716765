import React, { useCallback, useState } from 'react'
import { Swiper } from 'swiper'
import { Swiper as SwiperReact } from 'swiper/react'
import { Pagination, Scrollbar, A11y, Grid, FreeMode } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
import clsx from 'clsx'
import styles from './SwiperSlider.module.scss'
import '@obeta/assets/theme/swiper.scss'

interface ISwiperSliderProps {
  options?: SwiperOptions
  pagination?: boolean
  scrollbar?: boolean
  children?: React.ReactNode
  className?: string
  leftAdornment?: TAdornment
  rightAdornment?: TAdornment
}

export type TAdornment = (navState: INavState) => JSX.Element

export interface INavState {
  isBeginning: boolean
  isEnd: boolean
}

const defaultSwiperOptions: SwiperOptions = {
  modules: [Pagination, Scrollbar, A11y, Grid, FreeMode],
  effect: undefined,
  direction: 'horizontal',
  initialSlide: 0,
  loop: false,
  parallax: false,
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 300,
  grid: {
    rows: 1,
    fill: 'column',
  },
  slidesPerGroup: 1,
  centeredSlides: false,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  touchEventsTarget: 'container',
  autoplay: false,
  freeMode: {
    enabled: false,
    minimumVelocity: 0.02,
    momentum: true,
    momentumRatio: 1,
    momentumBounce: true,
    momentumBounceRatio: 1,
    momentumVelocityRatio: 1,
    sticky: false,
  },
  autoHeight: false,
  setWrapperSize: false,
  zoom: {
    maxRatio: 3,
    minRatio: 1,
    toggle: false,
  },
  touchRatio: 1,
  touchAngle: 45,
  simulateTouch: true,
  touchStartPreventDefault: false,
  shortSwipes: true,
  longSwipes: true,
  longSwipesRatio: 0.5,
  longSwipesMs: 300,
  followFinger: true,
  threshold: 0,
  touchMoveStopPropagation: true,
  touchReleaseOnEdges: false,
  //   iOSEdgeSwipeDetection: false,
  //   iOSEdgeSwipeThreshold: 20,
  resistance: true,
  resistanceRatio: 0.85,
  // https://stackoverflow.com/questions/58747262/how-to-style-slides-on-the-edges-in-swiper-slider
  watchSlidesProgress: false,
  preventClicks: true,
  preventClicksPropagation: true,
  slideToClickedSlide: false,
  loopAdditionalSlides: 0,
  noSwiping: true,
  runCallbacksOnInit: true,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
  flipEffect: {
    slideShadows: true,
    limitRotation: true,
  },
  cubeEffect: {
    slideShadows: true,
    shadow: true,
    shadowOffset: 20,
    shadowScale: 0.94,
  },
  fadeEffect: {
    crossFade: false,
  },
  a11y: {
    prevSlideMessage: 'Previous slide',
    nextSlideMessage: 'Next slide',
    firstSlideMessage: 'This is the first slide',
    lastSlideMessage: 'This is the last slide',
  },
}

const paginationDefaultOptions = {
  clickable: true,
}

const scrollbarDefaultOptions = {
  draggable: true,
}

export const SwiperSlider = React.memo(
  React.forwardRef<Swiper, ISwiperSliderProps>(function SwiperSlider(props, ref) {
    const { options, pagination, scrollbar, leftAdornment, rightAdornment, className, ...rest } =
      props
    const swiperOptions = Object.assign({}, defaultSwiperOptions, options)
    const mergedPagination = props.pagination
      ? Object.assign({}, paginationDefaultOptions, pagination)
      : false
    const mergedScrollbar = props.scrollbar
      ? Object.assign({}, scrollbarDefaultOptions, scrollbar)
      : false
    const [navState, setNavState] = useState({ isBeginning: false, isEnd: false })

    const navStateUpdateHandler = useCallback((swiper: Swiper) => {
      setNavState({
        isBeginning: swiper.isBeginning,
        isEnd: swiper.isEnd,
      })
    }, [])

    const setController = (swiper: Swiper) => {
      if (typeof ref === 'function') {
        ref(swiper)
      } else if (ref) {
        ref.current = swiper
      }
    }

    return (
      <div className={clsx(styles.root, className)}>
        {leftAdornment?.(navState)}
        <SwiperReact
          key={options?.slidesPerView === 'auto' ? 'auto' : 'fixed'} //use this key as changing the slidesper view form fixed to auto will leave dangeling widhts on slides elsewise
          onSwiper={setController}
          {...swiperOptions}
          pagination={mergedPagination}
          scrollbar={mergedScrollbar}
          {...rest}
          onInit={navStateUpdateHandler}
          onSlideChange={navStateUpdateHandler}
        >
          {props.children}
        </SwiperReact>
        {rightAdornment?.(navState)}
      </div>
    )
  })
)
