import React from 'react'
import styles from './NavigateButton.module.scss'
import { ReactComponent as ChevronLeftIcon } from '@obeta/assets/icon/chevron_left20x20.svg'
import { ReactComponent as ChevronRightIcon } from '@obeta/assets/icon/chevron_right20x20.svg'
import clsx from 'clsx'
import { IconButton } from '../icon-button/IconButton'

export interface INavigateButtonProps {
  onClick: React.MouseEventHandler
  direction: 'left' | 'right'
  className?: string
  margin?: boolean
  disabled?: boolean
}

export const NavigateButton: React.FC<INavigateButtonProps> = (props) => {
  const { margin = true, disabled, className, onClick, direction } = props
  return (
    <IconButton
      onClick={onClick}
      className={clsx({ [styles.navigate]: margin }, className)}
      icon={direction === 'right' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      disabled={disabled}
    />
  )
}
